<template>
    <div class="h-full">
        <!-- <bh-loading :show="loading" /> -->
        <portal to="destination">
            <!-- <a-button size="large" class="text-black mx-4" type='link' @click="skip">SKIP</a-button> -->
            <a-button :disabled="Object.keys(this.floorPlates).length === floors.length ? false:true" size="large" type='purple' class="back-button" @click="next" >NEXT</a-button>

        </portal>
        <div class="mb-5" v-for="(floor, floorI) in floors" :key="floorI">

            <h2>{{floor.name || `Floor ${floor.order}`}}</h2>
            <h5>Floor Plate Image</h5>

                <ImageBox @sized="(e) => imgSized(e, floor.siteplan.id)" @callback="(img) => selectImg(img, floor.siteplan.id)" :img="!floorPlates[floor.siteplan.id] ? '': `${floorPlates[floor.siteplan.id].image}`" />
        </div>
        <div v-if="floors.length == 0" class="dF aC" style="height: calc(100vh - 140px);">
            <h3>You do not have any floors, please go to the CondoUnits app to add your floors...</h3>
        </div>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'
import bhLoading from 'bh-mod/components/common/Loading'
import ImageBox from '@/components/siteplan/ImageBox'
export default {

    props:{
        value:Number
    },
    components:{ImageBox,bhLoading},
    data:() => ({
        currentStep:0,
    }),
    data() {
        return{
            floorPlates:{},
            siteplanImage:'',
            newImage:[],
            loading:false,
        }
    },
    computed:{
        floors() {
            let floors = Object.values(this.$store.state.condogrid.floors).sort( (a,b) => a.order - b.order)
            return floors
        }


    },
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        },
    },
    methods:{
        imgSized({width,height}, id){

            this.floorPlates[id].width = width
            this.floorPlates[id].height = height

        },

        selectImg(img, id) {
            console.log('SELECTED IMAGE', img, id)
            if (!this.floorPlates[id]) this.floorPlates[id]  = {}
            this.floorPlates[id].image = img
            this.$forceUpdate()
            // this.floorPlates[this.index].image = data
            // console.log('this floor', this.floors[this.index])
        },
        skip(){
            return this.$emit('next')
        },
        async next(){
            let entries = Object.entries(this.floorPlates)
            console.log('entries', entries)
            for (const i in entries) {
                if (entries.hasOwnProperty(i)) {
                    const item = entries[i];

                    let [id,value] = item
                    if (value.image) {
						try {
							console.log('updating siteplan')
							let result = await this.$api.put(`/siteplans/:instance/${id}`, value)
							console.log('updated siteplan')
							console.log('result', result)
							this.$store.commit('SET_SITEPLAN', result.data)
							this.$store.commit('SET_DATA')
							this.$store.commit('SET_CURRENTFLOOR')
						} catch (err) {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						}
					}
                }
            }
            console.log('routing to /')
            // this.loading = true
            this.$api.put(`/condos/:instance/${this.$store.state.condogrid.condo.id}`, {gridStarted:true}).then( () => {
                this.$router.push('/')
                // this.loading = false
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})

        },
        req(msg) {
            return {required:true, message:msg}
        }

    }

}
</script>

<style>

</style>
